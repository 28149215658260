import request from '@/utils/request'


// 查询客服分组列表
export function listGroup(query) {
  return request({
    url: '/customer/groups/list',
    method: 'get',
    params: query
  })
}

// 查询客服分组详细
export function getGroup(data) {
  return request({
    url: '/customer/groups/detail',
    method: 'get',
    params: data
  })
}

// 新增客服分组
export function addGroup(data) {
  return request({
    url: '/customer/groups/add',
    method: 'post',
    data: data
  })
}

// 修改客服分组
export function updateGroup(data) {
  return request({
    url: '/customer/groups/edit',
    method: 'post',
    data: data
  })
}

// 删除客服分组
export function delGroup(data) {
  return request({
    url: '/customer/groups/delete',
    method: 'post',
    data: data
  })
}
